import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Subject from "@material-ui/icons/Subject";
import SectionFeatures from "./SectionPricingFeature.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import pricingStyle from "assets/jss/material-kit-pro-react/views/presentationSections/pricingStyle.js";

const useStyles = makeStyles(pricingStyle);

export default function SectionPricing() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
     
          <GridContainer>
          <GridItem xs={12} sm={12} md={4}></GridItem>
         
            <GridItem xs={12} sm={12} md={4} className={classes.mrAuto}>
            <a id="buslocpricing">
            <h2 className={classes.title} style={{fontSize:'30px'}}>BUSLOC Pricing</h2>
          </a>
                <div class="box a" data-aos="flip-right"  data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="3000">
            <Card pricing raised color="info">
                <CardBody pricing>
                  <p style={{fontSize:30,fontWeight:600,color:"#FFFFFF"}}>BUSLOC IS FREE</p>
                 {/*  <p className={classes.cardTitleWhite} style={{fontSize:"40px",fontWeight:600}}>
                    <small style={{color:"#ffffff"}}>$</small> 0 <small style={{color:"#ffffff"}}>/mo</small>
                  </p>
                  <ul>
                    <li>
                     PARENTS
                    </li>
                    <li>
                      TRANSPORT AUTHORITY
                    </li>
                    <li>
                      SCHOOL BUS DRIVERS
                    </li>
                    <li>
                      SCHEDULERS
                    </li>
                  </ul> */}
                </CardBody>
                <CardFooter pricing className={classes.justifyContentCenter}>
                  <Button color="rose" round href="/schoolsintrested">
                  <Subject /> Get Started
                  </Button>
                </CardFooter>
              </Card>
              </div>
            </GridItem>
           {/*  <GridItem xs={12} sm={8} md={8} lg={9} className={classes.mrAuto}>
              <SectionFeatures/>
            </GridItem> */}
          </GridContainer>
       
      </div>
    </div>
  );
}
