/*eslint-disable*/ import React ,{ useEffect, useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Box from "@material-ui/core/Box";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";
import { Link } from "gatsby";
// Sections for this page
import SectionProduct from "./Sections/SectionProduct.js";
import SectionTeam from "./Sections/SectionTeam.js";
import SectionWork from "./Sections/SectionWork.js";
import SectionProjects from "./Sections/SectionProjects.js";
import SectionHowitWorks from "./Sections/SectionHowitWorks.js";
import SectionFeatures from "./Sections/SectionFeatures.js";
import SectionCards from "./Sections/SectionCards.js";
import SectionPricing from "./Sections/SectionPricing.js";
import Sectionofferings from "./Sections/Sectionofferings.js";
import {Helmet} from "react-helmet";
import logo from "assets/img/svg/busloclogo.png";
import AOS from 'aos';
import Carousel from "react-slick";
import dg1 from "assets/img/dg1.jpg";
import dg2 from "assets/img/dg2.jpg";
import dg3 from "assets/img/dg3.jpg";

import Share from "@material-ui/icons/Share";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Features from "assets/img/main.svg";
import Trips from "assets/img/trips.png";
import Maps from "assets/img/map.png";
import Managealert from "assets/img/managealerts.png";
import Routestopalert from "assets/img/alert.png"
import whitebg from "assets/img/white.jpg";
import 'aos/dist/aos.css';
import CardHeader from "components/Card/CardHeader.js";
import { motion, AnimatePresence,useViewportScroll, useSpring,
  useTransform} from 'framer-motion';
  import styled, { keyframes } from 'styled-components';
import { bounce } from 'react-animations';
const useStyles = makeStyles(landingPageStyle);
import UpdateButton from "../Layout/updatebutton.js";

const bounceAnimation = keyframes`${bounce}`;
 
const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;


export default function LandingPage({ ...rest }) {
 
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    AOS.init();
  });
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows:false
  };
  const [isComplete, setIsComplete] = useState(false);
  const { scrollYProgress } = useViewportScroll();
  const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1]);
  const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });
  const schemaOrgJSONLD = [{
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "BUSLOC",
    'description':'BUSLOC send free Mobile Notifications and live Tracking of school buses, Easy to implement, Free for everyone',
    "publisher": {
      "@type": "Organization",
      "name": "BUSLOC",
      "legalName" : "BUSLOC Inc",
      "url": "https://www.busloc.io",
       "email": "support@busloc.io"
    }
},{
"@context": "http://schema.org",
"@type": "WebSite",
  "name": "BUSLOC",
  "url": "https://www.busloc.io",
},
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "BUSLOC Introduction",
  "description": "A Introduction to BUSLOC Parents Platform", 
  "thumbnailUrl": "https://img.youtube.com/vi/vFkk7BeJ3-g/hqdefault.jpg",
  "uploadDate": "2020-10-05T08:00:00+02:00",
  //"duration": "PT1M33S",
  "contentUrl": "https://youtu.be/vFkk7BeJ3-g?list=TLGG4If0_5qmcd4wMjA0MjAyMQ",
  "embedUrl": "https://www.youtube.com/embed/vFkk7BeJ3-g?rel=0&loop=1&playlist=vFkk7BeJ3-g"
},
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "BUSLOC Demo",
  "description": "A BUSLOC Demo showing all features of BUSLOC Platform", 
  "thumbnailUrl": "https://img.youtube.com/vi/-WufJ4qxBLo/hqdefault.jpg",
  "uploadDate": "2020-10-05T08:00:00+02:00",
  //"duration": "PT1M33S",
  //https://youtu.be/-WufJ4qxBLo?list=TLGGXv_XhUpIhScwMjA0MjAyMQ
  "contentUrl": "https://youtu.be/vFkk7BeJ3-g?list=TLGG4If0_5qmcd4wMjA0MjAyMQ",
  "embedUrl": "https://www.youtube.com/embed/-WufJ4qxBLo?rel=0&loop=1&playlist=-WufJ4qxBLo"
},
{
  "@context": "https://www.schema.org",
  "@type": "product",
  "brand": "BUSLOC",
  "offers":{
    "@type":"Offer",
    "url":"https://www.busloc.io/#buslocpricing",
    "priceCurrenct":"USD",
    "price":"0"
  },
  //"logo": "http://www.example.com/logo.png",
  "name": "BUSLOC Parents App",
  //"category": "Widgets",
  //"image": "http://www.example.com/image.jpg",
  "description": "BUSLOC Parents App",
  
}
];

  const classes = useStyles();

  return (
    <>
  
    <div>
    <Helmet>
    <script type="application/ld+json">
   {JSON.stringify(schemaOrgJSONLD)}
 </script>
    </Helmet>
    <UpdateButton/>
    <Box display={{ xs: 'none', md: 'block' }}>
    <Carousel {...settings}>
          {/* Carousel 1 START */}
          <div>
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${dg1}")` }}
            >
             
              <div className={classes.container}>
                <div style={{textAlign:'center',marginTop:30}}>
              <Button style={{fontWeight:700,marginTop:10}} round color="rose"  href="/scheduleademo">Schedule a demo </Button> 
              </div>
                <GridContainer>
                <GridItem xs={12} sm={6} md={6} style={{textAlign:'left'}}>
               {/* */}
                <CardHeader image> 
                <img src={Trips} alt="..." />
              {/*   <img src={Features} alt="..." width="700" height="500" /> */}
                </CardHeader>
              
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} style={{textAlign:'left'}}>
              {/*     <Button style={{fontWeight:700,marginTop:10}} round color="rose"  href="/schoolsintrested">Get Started</Button> */}
                  <CardHeader image  > 
                <img src={Maps} alt="..." />
                </CardHeader>
              
                  </GridItem>
                </GridContainer>
               
              </div>
            </div>
          </div>
          {/* Carousel 1 END */}
          {/* Carousel 2 START */}
          <div>
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${dg2}")` }}
            >
              <div className={classes.container}>
              <h2  className={classNames(
                      classes.mlAuto,
                      classes.mrAuto,
                      classes.textCenter
                    )} style={{textAlign:'center',fontWeight:'bold'}}>No More Waitings</h2>
              <GridContainer>
              
                  <GridItem xs={12} sm={6} md={6}>
                  <CardHeader image  > 
                <img src={Managealert} alt="..." />
                </CardHeader>
                    
                 {/*    <h1  className={classNames(
                      classes.mlAuto,
                      classes.mrAuto,
                      classes.textCenter
                    )} >BUSLOC</h1>
                    <h4 style={{fontWeight:'bold'}}>
                    <Link style={{color:"#e91e63",fontWeight:600,fontSize:25}}>FREE</Link>  School Bus GPS Tracking with Location Alerts and Live Map
                    </h4>
                    <br />
                    <Button style={{fontWeight:700}} round color="rose"  href="https://cp.busloc.io/auth/signup">SignUp</Button>
               <Button style={{fontWeight:700}} round color="info"  href="https://cp.busloc.io/auth/signin">Signin</Button> */}
                   
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                <CardHeader image > 
                <img src={Routestopalert} alt="..." />
                </CardHeader>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
          {/* Carousel 2 END */}
          {/* Carousel 3 START */}
          <div>
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${dg3}")` }}
            >
              <div className={classes.container}>
              <br />
              <br />
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={6}
                    md={6}
                    className={classNames(classes.mlAuto, classes.textRight)}
                  style={{textAlign:'center'}}>
                  
                  <div style={{textAlign:'center',marginTop:30}}>
                <Button style={{fontWeight:700}} round color="rose"  href="/scheduleademo">Schedule a demo </Button>
               <Button style={{fontWeight:700}} round color="rose"  href="/schoolsintrested">Get Started</Button>
               </div>
                    
                   
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                  <h1 className={classes.title}>School Districts will have less calls from parents</h1>
                {/*   <CardHeader image > 

                <img src={Screen4} alt="..." /> 
                </CardHeader> */}
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
          {/* Carousel 3 END */}
        </Carousel>
    </Box>
    <Box display={{ xs: 'block', md: 'none' }}>
    <Carousel {...settings}>
          {/* Carousel 1 START */}
          <div>
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${dg1}")` }}
            >
             
              <div className={classes.container}>
                <div style={{textAlign:'center',marginTop:30}}>
              <Button style={{fontWeight:700,marginTop:10}} round color="rose"  href="/scheduleademo">Schedule a demo </Button> 
              <br/>  <br/>  
              </div>
               
                  <CardHeader image  > 
                <img src={Maps} alt="..." />
                </CardHeader>
              
               
              </div>
            </div>
          </div>
          {/* Carousel 1 END */}
          {/* Carousel 2 START */}
          <div>
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${dg2}")` }}
            >
              <div className={classes.container}>
              <h2  className={classNames(
                      classes.mlAuto,
                      classes.mrAuto,
                      classes.textCenter
                    )} style={{textAlign:'center',fontWeight:'bold'}}>No More Waitings</h2>
             
                  <CardHeader image  > 
                <img src={Managealert} alt="..." />
                </CardHeader>
                    
              
              </div>
            </div>
          </div>
          {/* Carousel 2 END */}
          {/* Carousel 3 START */}
          <div>
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${dg3}")` }}
            >
              <div className={classes.container}>
              <br />
              <br />
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={6}
                    md={6}
                    className={classNames(classes.mlAuto, classes.textRight)}
                  style={{textAlign:'center'}}>
                  
                  <div style={{textAlign:'center',marginTop:30}}>
                <Button style={{fontWeight:700}} round color="rose"  href="/scheduleademo">Schedule a demo </Button>
               <Button style={{fontWeight:700}} round color="rose"  href="/schoolsintrested">Get Started</Button>
               </div>
                    
                   
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                  <h1 className={classes.title}>School Districts will have less calls from parents</h1>
                {/*   <CardHeader image > 

                <img src={Screen4} alt="..." /> 
                </CardHeader> */}
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
          {/* Carousel 3 END */}
        </Carousel>
    </Box>
     
        <Box display={{ xs: 'none', md: 'block' }}>
        <div className={classNames(classes.main)} style={{backgroundImage: `url(${whitebg})`}}>
        <div className={classes.container} >
       
               <SectionFeatures/>
            <SectionProjects/>
          
           
       <Sectionofferings/>
         
         
         {/*  <SectionHowitWorks/>
          <br/>
        <SectionCards/> */}
        <SectionPricing/>
       {/*  <SectionWork/> */}
           {/*  </GridItem>
           
          </GridContainer> */}
          
      
        </div>
      </div>
          </Box>
          <Box display={{ xs: 'block', md: 'none' }}>
          <div className={classNames(classes.main)} style={{backgroundColor:'#FFFFFF'}}>
        <div className={classes.container} >
       
               <SectionFeatures/>
            <SectionProjects/>
          
           
       <Sectionofferings/>
         
         
         {/*  <SectionHowitWorks/>
          <br/>
        <SectionCards/> */}
        <SectionPricing/>
       {/*  <SectionWork/> */}
           {/*  </GridItem>
           
          </GridContainer> */}
          
      
        </div>
      </div>

          </Box>
   
     
    </div>
   
   </>
  );
}
