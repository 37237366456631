import React, { useContext, useEffect, useState,useCallback } from "react";
import { useSWUpdateChecker } from "service-worker-updater"
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import { makeStyles } from "@material-ui/core/styles";
const CHECK_INTERVAL = 1 * 60 * 1000 // 1 minute
const UPDATE_ON_NAVIGATE = true // trigger update when changing route/window.location
const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export default function UpdateButton(){
  const [hasUpdate, updateHandler] = useSWUpdateChecker({
    checkInterval: CHECK_INTERVAL,
    updateOnLoad: UPDATE_ON_NAVIGATE
  });
  const classes = useStyles();
  const [smallModal, setSmallModal] = React.useState(false);
  if (!hasUpdate) return null;
 
  return (
    <>
     <Dialog
    classes={{
      root: classes.center + " " + classes.modalRoot,
      paper: classes.modal + " " + classes.modalSmall
    }}
    open={hasUpdate}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => setSmallModal(false)}
    aria-labelledby="small-modal-slide-title"
    aria-describedby="small-modal-slide-description"
  >
    <DialogTitle
      id="small-modal-slide-title"
      disableTypography
      className={classes.modalHeader}
    >
     
    </DialogTitle>
    <DialogContent
      id="small-modal-slide-description"
      className={
        classes.modalBody + " " + classes.modalSmallBody
      }
    >
      <h5>New update available, Click ok to install the update.</h5>
    </DialogContent>
    <DialogActions
      className={
        classes.modalFooter + " " + classes.modalFooterCenter
      }
    >
      <Button
        onClick={() => {
          updateHandler();
          setSmallModal(false);
        }}
        color="info"
        round
        className={classes.modalSmallFooterFirstButton}
      >
       Ok
      </Button>
     
    </DialogActions>
  </Dialog>

    </>
  )
}

