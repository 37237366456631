import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons

import Subject from "@material-ui/icons/Subject";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForward from "@material-ui/icons/ArrowForward";
import More from "@material-ui/icons/More";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Rose from "components/Typography/Rose.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";
import Transport from "assets/img/svg/trns.svg";
import Parent from "assets/img/svg/prnt.svg";

const useStyles = makeStyles(styles);

export default function SectionCards() {
  const [activeRotate1, setActiveRotate1] = React.useState("");
  const [activeRotate2, setActiveRotate2] = React.useState("");
  const [activeRotate3, setActiveRotate3] = React.useState("");
  const classes = useStyles();
  React.useEffect(() => {
    if (window) {
      window.addEventListener("resize", addStylesForRotatingCards);
    }
    addStylesForRotatingCards();
    return function cleanup() {
      if (window) {
        window.removeEventListener("resize", addStylesForRotatingCards);
      }
    };
  });
  const addStylesForRotatingCards = () => {
    var rotatingCards = document.getElementsByClassName(classes.cardRotate);
    for (let i = 0; i < rotatingCards.length; i++) {
      var rotatingCard = rotatingCards[i];
      var cardFront = rotatingCard.getElementsByClassName(classes.front)[0];
      var cardBack = rotatingCard.getElementsByClassName(classes.back)[0];
      cardFront.style.height = "unset";
      cardFront.style.width = "unset";
      cardBack.style.height = "unset";
      cardBack.style.width = "unset";
      var rotatingWrapper = rotatingCard.parentElement;
      var cardWidth = rotatingCard.parentElement.offsetWidth;
      var cardHeight = rotatingCard.children[0].children[0].offsetHeight;
      rotatingWrapper.style.height = cardHeight + "px";
      rotatingWrapper.style["margin-bottom"] = 30 + "px";
      cardFront.style.height = "unset";
      cardFront.style.width = "unset";
      cardBack.style.height = "unset";
      cardBack.style.width = "unset";
      cardFront.style.height = cardHeight + 35 + "px";
      cardFront.style.width = cardWidth + "px";
      cardBack.style.height = cardHeight + 35 + "px";
      cardBack.style.width = cardWidth + "px";
    }
  };
  return (
    <div className="cd-section" id="cards">
      <div>
        <div>
          {/* BLOG CARDS START */}
          <div>
            <div className={classes.container}>
            <h3 className={classes.title} style={{fontSize:'35px'}}>Get Started with BUSLOC</h3>
            <br/>
            <GridContainer>
              <GridItem xs={12} sm={12} md={2}></GridItem>
              <GridItem xs={12} sm={12} md={8}>
              <div class="box a" data-aos="fade-up"  data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="2000">
              <h4 className={classes.cardTitle} style={{textAlign:'left'}}>
              School District Staff
                      {/*   <a href="#pablo" onClick={e => e.preventDefault()}>
                       
                        </a> */}
                      </h4>
                   
              <List className={classes.description}>
        <ListItem>
          <ListItemIcon style={{color:"#e91e63",fontWeight:500}}>
            <ArrowForward color="rose"/>
          </ListItemIcon>
          <ListItemText>
          Signup with BUSLOC <a href="https://cp.busloc.io/auth/signup" style={{color:"#e91e63"}}>https://cp.busloc.io/auth/signup</a> and complete the School District/School registration
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon style={{color:"#e91e63",fontWeight:500}}>
            <ArrowForward color="rose"/>
          </ListItemIcon>
          <ListItemText>
          BUSLOC will process the request and provides Dashboard access with demo data within 24 hrs. 
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon style={{color:"#e91e63",fontWeight:500}}>
            <ArrowForward color="rose"/>
          </ListItemIcon>
          <ListItemText>
          BUSLOC will followup with one-on-one online meeting, data collection etc.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon style={{color:"#e91e63",fontWeight:500}}>
            <ArrowForward color="rose"/>
          </ListItemIcon>
          <ListItemText>
          BUSLOC will also send instructions on populating the data on schools, routes, routestops, and GPS hardware.
          </ListItemText>
        </ListItem>
        </List>
        <div>
        <Button round color="rose" style={{fontWeight:700}} href="https://cp.busloc.io/auth/signup">
                        <Subject /> Get Started
                        </Button>
                        
                        </div>
                        </div>
              </GridItem>
            </GridContainer>
            <br/>
            {/*   <GridContainer>
              <GridItem xs={12} sm={12} md={1} lg={1}>
                 
                 
                 </GridItem>
               
                <GridItem xs={12} sm={12} md={5}>
                <div class="box a" data-aos="fade-up"  data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="2000">
                  <Card blog>
                    <CardHeader image color="warning">
                    <img src={Transport} alt="..." />
                    
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${Transport})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                    <CardBody>
                     
                      <h4 className={classes.cardTitle}>
                      Transportation Staff
                     
                      </h4>
                      <Button round color="rose" style={{fontWeight:700}} href="https://cp.busloc.io/auth/signup">
                        <Subject /> Get Started
                        </Button>
                    </CardBody>
                   
                  </Card>
                  </div>
                  
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                <div class="box a" data-aos="fade-up"  data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="2000">
                  <Card blog>
                    <CardHeader image color="warning">
                    <img src={Parent} alt="..." />
                    
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${Parent})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                    <CardBody>
                    
                      <h4 className={classes.cardTitle}>
                      Parents/Students
                      
                      </h4>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                        <a href='https://play.google.com/store/apps/details?id=com.schoolbus.tracking&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' style={{padding:0}}><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' width="150" height="60"/></a>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                        <a href="https://apps.apple.com/us/app/schoolbus-tracking/id998824070?mt=8" style={{display: 'inline-block',overflow: 'hidden',backgroundRepeat: 'no-repeat',marginTop:10,padding:0}}><img alt='Get it on App Store' src='https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2016-09-15&kind=iossoftware&bubble=ios_apps' width="135" height="40"/></a>
                        </GridItem>
                      </GridContainer>
                     
              
              
                    </CardBody>
                   
                  </Card>
                  </div>
                  
                </GridItem>
                <GridItem xs={12} sm={12} md={1} lg={1}>
                 
                 
                </GridItem>
              </GridContainer> */}
            </div>
          </div>
          {/* BLOG CARDS END */}
          
        
       
        </div>

       
      </div>
    
    </div>
  );
}
