import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Language from "@material-ui/icons/Language";
import Group from "@material-ui/icons/Group";
import DepartureBoard from "@material-ui/icons/DepartureBoard";
import Notifications from "@material-ui/icons/Notifications";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import Coronavirus from "@material-ui/icons/Coronavirus";
import Button from "components/CustomButtons/Button.js";
import Room from "@material-ui/icons/Room";
import Settings from "@material-ui/icons/Settings";
import Search from "@material-ui/icons/Search";

//import VerifiedUser from "@material-ui/icons/VerifiedUser";
//import Fingerprint from "@material-ui/icons/Fingerprint";
//import GroupWork from "@material-ui/icons/GroupWork";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import InfoAreaBig from "components/InfoAreaBig/InfoAreaBig.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

import iphone from "assets/img/screen4.png";
import iphone2 from "assets/img/sections/iphone2.png";
import bg9 from "assets/img/bg9.jpg";
import styled, { keyframes } from 'styled-components';
import { bounce } from 'react-animations';
import { motion } from "framer-motion";
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import ScrollAnimation from "react-animate-on-scroll";
import { Grid } from "@material-ui/core";
import Features from "assets/img/changedfeatures.png";
import cardbg from "assets/img/block.jpg";
import Corona from "assets/img/corona.png"
import CardHeader from "components/Card/CardHeader.js";
import Box from "@material-ui/core/Box";


const bounceAnimation = keyframes`${bounce}`;
 
const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;

const useStyles = makeStyles(featuresStyle);
const useStyle = makeStyles((theme) => ({
  root: {
    height: 180,
  },
  wrapper: {
    width: 100 + theme.spacing(2),
  },
  paper: {
    zIndex: 1,
    position: 'relative',
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
}));



export default function SectionFeatures({ ...rest }) {
  const classes = useStyles();
  const classe = useStyle();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
      <div  style={{textAlign:'center'}}>
      <h1 className={classes.title} style={{marginTop:"20px"}}>
             
             BUSLOC – FREE School Bus App for Parents

             </h1>
          
             </div>
      <GridContainer>
            <GridItem md={3}>

            </GridItem>
            <GridItem md={6}>
            <div  style={{backgroundColor:'#2196F3',textAlign:'center',borderRadius: "12px"}}>
        
       
        <p className={classes.title} style={{ fontSize: '28px',color:"#FFFFFF",paddingTop:20,marginBottom:30}}>Is Your Fleet BUSLOC Ready?</p>
        <p className={classes.title} style={{ fontSize: '16px',color:"#FFFFFF",marginBottom:10}}>Any School Bus is ready for BUSLOC</p>
        <Button style={{fontWeight:700,marginTop:10}} round color="rose"  href="/schoolsintrested">Get Started</Button>
       
        </div>
            </GridItem>
          </GridContainer>
        {/* Feature 1 START */}
        <div
        className={
          classes.sectionLite
        }
        
      >
      
         <Slide direction="up" in={true} mountOnEnter unmountOnExit>
       
        <div className={classes.features1}>
       
          <GridContainer>
           
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <a id="buslocfeatures">
              <h2 className={classes.title} style={{fontWeight:600}} >BUSLOC Features</h2>
              </a>
              <p style={{fontSize:16,color:"#3C4858",fontWeight:'500'}}>(School Districts can choose features based on the requirement)</p>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={2}></GridItem>
            <GridItem md={8}>
            <Box display={{ xs: 'none', md: 'block' }}>
            <CardHeader image> 
              
           <img src={Features} alt="..." width="700" height="500" />
                </CardHeader>
                </Box>
            </GridItem>
          </GridContainer>
          <GridContainer>
      <GridItem  md={1}></GridItem>
      <GridItem xs={12} sm={12} md={10}>
        <div style={{textAlign:'left',backgroundColor:"#fff1d0"}}>
       <br/>
      <p className={classes.title} style={{fontWeight:'800',fontSize:'20px',marginLeft:10,marginTop:10}}>BUSLOC Parents App is highly customizable.<br/> <br/>School Districts can decide on which features to use in the 
      Parents App irrespective of the fleet is equipped with GPS hardware or not.</p>
      <GridContainer>
      <GridItem  md={1}></GridItem>
      <GridItem xs={12} sm={12} md={10}>
                <p style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:15}}>BUSLOC Features that do not require GPS</p>

                <ul style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>
                  <li>Communications</li>
                  <li>Latest schedule</li>
                  <li>COVID-19 checks</li>
                  <li>Student Tracking</li>
                </ul>

                <p style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:15}}>BUSLOC Features that require GPS</p>
                <ul style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>
                  <li>Real-time Tracking </li>
                  <li>Location-based alerts</li>
                 
                </ul>
              
                </GridItem>
                </GridContainer>
                </div>
                <h4 className={classes.title} style={{fontWeight:'800',textAlign:'left'}}>BUSLOC Driver/Attendant App is required for Student Attendance.</h4>
                </GridItem>
                </GridContainer>
                
               
      {/* <motion.div  whileHover={{ scale: 1.1 }}> */}
      <GridContainer>
      <GridItem  md={1}></GridItem>
      <GridItem xs={12} sm={12} md={10}>
      <InfoAreaBig
 
                icon={Language}
                title={
                <>
                <div style={{backgroundColor:"#2196F3", borderRadius:20}}>
                  <h4 style={{ margin: "1.75rem 0 0.875rem !important",
    fontSize:24,
    color: "#FFFFFF",
    textDecoration: "none",
    fontWeight: "700",
    marginTop: "20px",
    marginBottom: "5px",
    minHeight: "32px",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    minHeight: "unset",
    marginLeft:15,
    }}>Communications</h4>
    </div>
  
 <p className={classes.cardCategory} style={{fontStyle:'italic',fontSize:14}}>Feature Requirements - None</p>
                
               </>
              }
              
                description={
                 
                  <span>
                    
                   
                  </span>
                   
                }
                iconColor="blue"
              />
               <div style={{textAlign:'left',backgroundColor:"#fff1d0"}}>
       <br/>
       <p style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>BUSLOC supports communications with parents in multiple ways:</p>
                    <GridContainer>
      <GridItem  md={1}></GridItem>
      <GridItem xs={12} sm={12} md={10}>
                <p style={{fontSize:16,color:"#3C4858",fontWeight:'800'}}>Send Notifications to Parents as a Group</p>
                <p style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>Transportation staff can send notifications to Parents on bus delays, schedule updates etc. Notifications can be sent to</p>
                <ul style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>
                  <li>all Parents in the School District</li>
                  <li>Parents on a specific route</li>
                  <li>Parents scheduled on a routestop</li>
                  <li>Parents on AM routes</li>
                  <li>Parents on PM routes</li>
                </ul>

                <p style={{fontSize:16,color:"#3C4858",fontWeight:'800'}}>Direct Communication with Parents - (Coming Soon)</p>
                <p style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>Transportation staff and Parents can communicate one-on-one (live-chat)</p>
               
              {/*   <p style={{fontSize:16,color:"#3C4858",fontWeight:'800',fontStyle:'italic',marginLeft:10}}></p> */}
               
                 
                </GridItem>
                </GridContainer>
                </div>
     
                        <InfoAreaBig
                icon={Room}
                title={
                <>
                <div style={{backgroundColor:"#2196F3", borderRadius:20}}>
                  <h4 style={{ margin: "1.75rem 0 0.875rem !important",
    fontSize:24,
    color: "#FFFFFF",
    textDecoration: "none",
    fontWeight: "700",
    marginTop: "20px",
    marginBottom: "5px",
    minHeight: "32px",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    minHeight: "unset",
    
    marginLeft:15,
   
    }}>Real-Time Tracking</h4>
 
                </div>
                <p className={classes.cardCategory} style={{fontStyle:'italic',fontSize:14}}>Feature Requirements - GPS</p>
                </>
              }
           
                description={
                  <span>
            

                   
                  </span>
                }
                iconColor="yellow"
              />
               <div style={{textAlign:'left',backgroundColor:"#fff1d0"}}>
       <br/>
       <p style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>BUSLOC provides real-time tracking of school buses on a map in the Parents App.
“Real-Time Tracking” feature requires the School District fleet to be equipped with GPS. 
BUSLOC readily integrates with the fleet’s existing GPS system and displays the latest school bus location on a map in real-time in the Parents App.
</p>
<GridContainer>
      <GridItem  md={1}></GridItem>
    {/*   <GridItem xs={12} sm={12} md={10}>
               
                <p style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>School Districts which currently do not have GPS installed on their fleet have two options:</p>
                <ol style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>
                  <li>School Districts with plans to purchase/subscribe GPS devices for their fleet can go ahead and install the GPS devices. After the installation, BUSLOC will quickly work on integrating with the new GPS system.</li>
                  <li>School Districts with no plans to purchase GPS devices for their fleet can still make use of other features of BUSLOC Parents App.</li>
                 
                </ol>
               
              
                </GridItem> */}
                </GridContainer>
                <p style={{fontSize:20,color:"#3C4858",fontWeight:'600',marginLeft:10}}>
                No Extra Hardware
                  </p>
                  <p style={{fontSize:16,color:"#3C4858",fontWeight:'800',marginLeft:10}}>School Districts with Existing Hardware</p>
                          <p style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>BUSLOC readily integrates with the School District’s existing GPS system and in this way School Districts save huge sums of money.


                        </p>
                        <p style={{fontSize:16,color:"#3C4858",fontWeight:'800'}}></p>
                        <p style={{fontSize:16,color:"#3C4858",fontWeight:'500' ,marginLeft:10}}>School Districts that currently do not have GPS installed on their fleet have two options:
                        </p>

                        <ol style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>
                  <li>School Districts with plans to purchase/subscribe GPS devices for their fleet can go ahead and install the GPS devices. 
                    After the installation, BUSLOC will quickly work on integrating with the new GPS system.
                  </li>
                  <li>School Districts with no plans to purchase GPS devices for their fleet can still make use of other features of BUSLOC Parents App.</li>
                 
                </ol>
                </div>

<InfoAreaBig
                icon={Notifications}
                title={
                <>
                <div style={{backgroundColor:"#2196F3", borderRadius:20}}>
                  <h4 style={{ margin: "1.75rem 0 0.875rem !important",
    fontSize:24,
    color: "#FFFFFF",
    textDecoration: "none",
    fontWeight: "700",
    marginTop: "20px",
    marginBottom: "5px",
    minHeight: "32px",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    marginLeft:15,
    minHeight: "unset"}}>Location Based Alerts</h4>
    </div>
  <p className={classes.cardCategory} style={{fontStyle:'italic',fontSize:14}}>Feature Requirements - GPS</p>
             
                </>
               
              }
             
                description={
                  <span>
                
                      {/*    <a href="#pablo" onClick={e => e.preventDefault()}>
                        Find more...
                      </a> */}
                 
                  </span>
                }
                iconColor="green"
              />
              <div style={{textAlign:'left',backgroundColor:"#fff1d0"}}>
       <br/>
       <p style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>BUSLOC provides two types of location-based alerts for Parents to set-up and modify at any time.

</p>
<GridContainer>
<GridItem  md={1}></GridItem>
<GridItem xs={12} sm={12} md={10}>
<p style={{fontSize:16,color:"#3C4858",fontWeight:'800',marginLeft:10}}>Zone-Based Alerts</p>
<p style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>Parents can set up zone-based alerts in the Parents App around a perimeter of their home and receive alerts when the bus enters this zone. 
</p>

<p style={{fontSize:16,color:"#3C4858",fontWeight:'800',marginLeft:10}}>RoueStop-Based Alerts</p>
<p style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>Parents can also set up alerts in the Parents App based on the bus approaching previous stops (or any of the stops) on the route. Parents can set up alerts on any number of available routestops.
</p>
</GridItem>
</GridContainer>
                </div>

<InfoAreaBig
                icon={DepartureBoard}
             
                title={
                <>
                <div style={{backgroundColor:"#2196F3", borderRadius:20}}>
                  <h4 style={{ margin: "1.75rem 0 0.875rem !important",
    fontSize:24,
    color: "#FFFFFF",
    textDecoration: "none",
    fontWeight: "700",
    marginTop: "20px",
    marginBottom: "5px",
    minHeight: "32px",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    marginLeft:15,
    minHeight: "unset"}}>Latest Schedule</h4>
    </div>
 <p className={classes.cardCategory} style={{fontStyle:'italic',fontSize:14}}>Feature Requirements - None</p>
            
                </>
               
              }
                description={
                  <span>
                   
                 {/*    <a href="#pablo" onClick={e => e.preventDefault()}>
                      Find more...
                    </a> */}
                   
                  </span>
                }
                iconColor="orangered"
              />
             
             <div style={{textAlign:'left',backgroundColor:"#fff1d0"}}>
       <br/>
       <p style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>BUSLOC always shows the latest route schedules (provided by the School Districts) as assigned routes in the Parents App, which includes start and end time, stop time, stop name etc.

</p>
<GridContainer>
<GridItem  md={1}></GridItem>
<GridItem xs={12} sm={12} md={10}>
<p style={{fontSize:16,color:"#3C4858",fontWeight:'800',marginLeft:10}}>School Districts without Routing Software</p>
<p style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>School Districts can provide the schedule information to BUSLOC, and BUSLOC guarantees that the latest schedule is always shown to Parents.
</p>

<p style={{fontSize:16,color:"#3C4858",fontWeight:'800',marginLeft:10}}>School Districts with Routing Software</p>
<p style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>BUSLOC readily integrates with the School District’s existing routing software, and always collects the latest information on students, routes, routestops etc. BUSLOC always ensures the latest schedule is shown to the Parents.
</p>
</GridItem>
</GridContainer>
                </div>
 
             
                 <InfoAreaBig
                icon=""
                title={
                <>
                <div style={{backgroundColor:"#2196F3", borderRadius:20}}>
                  <h4 style={{ margin: "1.75rem 0 0.875rem !important",
    fontSize:24,
    color: "#FFFFFF",
    textDecoration: "none",
    fontWeight: "700",
    marginTop: "20px",
    marginBottom: "5px",
    minHeight: "32px",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    marginLeft:15,
    minHeight: "unset"}}>COVID-19 Checks</h4>
    </div>
  <p className={classes.cardCategory} style={{fontStyle:'italic',fontSize:14}}>Feature Requirements - None</p>
              </>
                 
              }
              
           
                description={
                  <span>
                  
                   
                  </span>
                }
                iconColor="pink"
              />
                   <div style={{textAlign:'left',backgroundColor:"#fff1d0"}}>
       <br/>
       <p style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:10}}>
                   
                   BUSLOC provides a checklist based on the CDC guidelines, and these are readily available for use in the App.
                   </p>
                </div>
                <InfoAreaBig
                icon={Search}
                title={
                <>
                <div style={{backgroundColor:"#2196F3", borderRadius:20}}>
                  <h4 style={{ margin: "1.75rem 0 0.875rem !important",
    fontSize:24,
    color: "#FFFFFF",
    textDecoration: "none",
    fontWeight: "700",
    marginTop: "20px",
    marginBottom: "5px",
    minHeight: "32px",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    marginLeft:15,
    minHeight: "unset"}}>Student Tracking</h4>
    </div>
 <p className={classes.cardCategory} style={{fontStyle:'italic',fontSize:14}}>Feature Requirements – Mobile with internet access in the bus</p>
               </>
               
              }
               
                description={
                  <span>
                    
                        {/*    <a href="#pablo" onClick={e => e.preventDefault()}>
                          Find more...
                        </a> */}
                   
                  </span>
                }
                iconColor="purple"
              />

<div style={{textAlign:'left',backgroundColor:"#fff1d0"}}>
       <br/>
      
                        <p style={{fontSize:16,color:"#3C4858",fontWeight:'800',marginLeft:10}}>BUSLOC provides a separate mobile app (Driver/Attendant APP) for student attendance and tracking.</p>
                         

                        <ol style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:20}}>
                  <li>Check-in and Check-out students
                  </li>
                  <li>At each boarding stop, check-in each of the students boarding</li>
                  <li>On arriving at school, check-out each of the students</li>
                  <li>On leaving school, check-in each the on-board students</li>
                  <li>At each drop-off stop, check-out each of the students dropping off</li>
                 
                </ol>
                <p style={{fontSize:16,color:"#3C4858",fontWeight:'800',marginLeft:10}}>Send message to Parents</p>
                <p style={{fontSize:16,color:"#3C4858",fontWeight:'500',marginLeft:20}}>Send messages (in the Parents App) to the parent each time the student boards/leaves the bus</p>
                     
                </div>
      </GridItem>
      </GridContainer>
   
      
      
     
      
        </div>
        </Slide>
       
        </div>
        
       </div>
       
    </div>
  );
}
