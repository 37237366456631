/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Build from "@material-ui/icons/Build";
import Subject from "@material-ui/icons/Subject";
import FormatPaint from "@material-ui/icons/FormatPaint";
import Code from "@material-ui/icons/Code";
import Dashboard from "@material-ui/icons/Dashboard";
import Timeline from "@material-ui/icons/Timeline";
import Group from "@material-ui/icons/Group";
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Badge from "components/Badge/Badge.js";
import tracking from "assets/img/svg/tracking.svg";
import textmessage from "assets/img/svg/textmessage.svg";
import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";
import { Link } from "gatsby";
import office2 from "assets/img/examples/office2.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";
import cardProject1 from "assets/img/examples/card-project1.jpg";
import cardProject2 from "assets/img/examples/card-project2.jpg";
import cardProject3 from "assets/img/examples/card-project3.jpg";
import cardProject4 from "assets/img/examples/card-project4.jpg";
import cardProject5 from "assets/img/examples/card-project5.jpg";
import cardProject6 from "assets/img/examples/card-project6.jpg";
import covid19banner from "assets/img/svg/corona.svg";
import Features from "assets/img/features.png";
import Screen1 from "assets/img/screen4.png";
import Share from "@material-ui/icons/Share";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import { motion, AnimatePresence,useViewportScroll } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import { bounce } from 'react-animations';
import Divider from '@material-ui/core/Divider';
import Primary from "components/Typography/Primary.js";
import Carousel from "react-slick";
import dg1 from "assets/img/dg1.jpg";
import dg2 from "assets/img/dg2.jpg";
import dg3 from "assets/img/dg3.jpg";


const useStyles = makeStyles(projectsStyle);
const bounceAnimation = keyframes`${bounce}`;
 
const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;

export default function SectionProjects({ ...rest }) {
  const { scrollYProgress } = useViewportScroll();
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows:false
  };
  return (
    <div className="cd-section" {...rest} >
      
   
      {/* Project 3 START */}
      <div
        className={
          classes.projects + " " + /* classes.sectionDark */ + " " + classes.projects3
        }
      >
        <div className={classes.container1} >
      
     
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
          
            </GridItem>
          </GridContainer> 
         
          <br/>

        
          <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                <h3 style={{color:"#3C4858",marginBottom:50,textAlign:'center',fontWeight:'600'}}>BUSLOC Introduction</h3>
                  <Card blog >
                    <CardHeader image>
                    <div className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" ,
        paddingTop: 25,
        height: 0,
        textAlign:'center'
      }}>
                           <iframe
        style={{
          position: "absolute",
          top: 0,
          textAlign:'center',
          left: 0,
          width: "100%",
          height: "100%"
        }}
       
        src={`https://www.youtube.com/embed/vFkk7BeJ3-g?rel=0&loop=1&playlist=vFkk7BeJ3-g`}
        allow='autoplay'
        allowFullScreen='allowFullScreen'
        frameBorder="0"
        setPlaybackQuality="hd1080"
        
      
      />
     
     </div>
                 
                     
                    </CardHeader>
                    <CardBody>
              
                  <h4 className={classes.cardTitle}>
                    
                   Any School Bus is ready to use BUSLOC 
                    </h4> 
               
                {/*  <p className={classes.description1}>
                 School District staff can send unlimited messages to Parents on school bus promptness, delays, breakdowns, route switching, schedules, etc. Parents subscribe to routestop based location alerts

                  </p>  */}
                  <Button round color="rose" href="/tagetstarted">
                    <Subject /> Learn more
                  </Button>
                 {/*  <Button round color="info" href="/contactus">
                    Contact us
                  </Button> */}
                </CardBody>
                  </Card>
               
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                <h3 style={{color:"#3C4858",marginBottom:50,textAlign:'center',fontWeight:'600'}}>BUSLOC Parents App Demonstration</h3>
                  <Card blog >
                    <CardHeader image>
                    <div className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" ,
        paddingTop: 25,
        height: 0,
        textAlign:'center'
      }}>
                           <iframe
        style={{
          position: "absolute",
          top: 0,
          textAlign:'center',
          left: 0,
          width: "100%",
          height: "100%"
        }}
       
        src={`https://www.youtube.com/embed/-WufJ4qxBLo?rel=0&loop=1&playlist=-WufJ4qxBLo`}
        allow='autoplay'
        allowFullScreen='allowFullScreen'
        frameBorder="0"
        setPlaybackQuality="hd1080"
        
      
      />
     
     </div>
                    </CardHeader>
                    <CardBody>
                   
                  <h4 className={classes.cardTitle}>
                    
                BUSLOC offers exclusive demo for you. Click on Schedule Demo 
                    </h4>
                 {/*  <a href="#pablo" onClick={e => e.preventDefault()}>
                   
                  </a> */}
               <Button round color="rose" href="/scheduleademo">
                   Schedule demo
                  </Button>
                 
                </CardBody>
                  </Card>
              

                </GridItem>
                </GridContainer>
            
               
       
        </div>
      </div>
      {/* Project 3 END */}
      {/* Project 4 START */}
    
      {/* Project 4 END */}
    </div>
  );
}
