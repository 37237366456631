import {
  container,
  mlAuto,
  mrAuto,
  grayColor,
  cardTitle,
  description,
  coloredShadow,
  whiteColor,
  sectionDark,
  hexToRgb,
  title
} from "assets/jss/material-kit-pro-react.js";

const projectsSection = {
  container,
  mlAuto,
  mrAuto,
  
  
  coloredShadow,
  cardTitle,
  textCenter: {
    textAlign: "center"
  },
  titlehead : {
    color: grayColor[1],
    textDecoration: "none",
    fontWeight: "700",
    fontSize:"22px",
    marginTop: "70px",
    marginBottom: "25px",
    minHeight: "32px",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`
  },
  title: {
    ...title,
    marginBottom: "1rem",
    color: grayColor[1],
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`
  },
  description:{
    color:whiteColor,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`
  },
  description1: {
    color: grayColor[1],
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "16px",
      fontWeight:400,
    "& p": {
      color: grayColor[1],
      fontSize: "12px",
      fontWeight:400
    }
  },
  
  projects: {
    padding: "80px 0"
  },
  tabSpace: {
    padding: "20px 0 50px 0px"
  },
  cardCategory: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.7) !important",
    marginTop: "10px",
    fontWeight:"900 !important"
  },
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + "  !important",
    marginTop: "10px !important"
  },
  cardDescription: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.7) !important",
    fontWeight:"600 !important"
  },
  sectionSpace: {
    height: "70px",
    display: "block"
  },
  marginTop20: {
    marginTop: "20px"
  },
  card2: {
    textAlign: "center"
  },
  cardTitleAbsolute: {
    ...cardTitle,
    position: "absolute !important",
    bottom: "15px !important",
    left: "15px !important",
    color: whiteColor + " !important",
    fontSize: "1.125rem !important",
    textShadow: "0 2px 5px rgba(" + hexToRgb(grayColor[9]) + ", 0.5) !important"
  },
  sectionDark: {
    ...sectionDark,
    backgroundSize: "550% 450%",
    "& $title, & $cardTitle": {
      color: grayColor[1]
    },
    "& $cardCategory": {
      color: "rgba(" + hexToRgb(grayColor[1]) + ", 0.5) !important",
      fontWeight:600
    },
    "& $cardDescription": {
      color: "rgba(" + hexToRgb(grayColor[1]) + ", 0.76) !important",
      paddingTop:"0px"
    }
  },
  projects3: {
    "& $title": {
      marginBottom: "80px",
      marginTop: "5px"
    },
    "& h6": {
      marginBottom: "5px"
    }
  },
  card4: {
    marginTop: "0",
    marginBottom: "0",
    "& $cardTitle": {
      color: whiteColor
    }
  },
  cardBody4: {
    paddingTop: "140px",
    paddingBottom: "140px"
  },
  info4: {
    padding: "0"
  },
  projects4: {
    "& hr": {
      margin: "70px auto",
      maxWidth: "970px"
    }
  }
};

export default projectsSection;

