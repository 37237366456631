import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Language from "@material-ui/icons/Language";
import Group from "@material-ui/icons/Group";
import DepartureBoard from "@material-ui/icons/DepartureBoard";
import Notifications from "@material-ui/icons/Notifications";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForward from "@material-ui/icons/ArrowForward";
import Room from "@material-ui/icons/Room";
import Search from "@material-ui/icons/Search";
import Airplay from "@material-ui/icons/Airplay";
import LocationOn from "@material-ui/icons/LocationOn";
import DirectionsBus from "@material-ui/icons/DirectionsBus";
import ChildFriendly from "@material-ui/icons/ChildFriendly";
import AccountBalance from '@material-ui/icons/AccountBalance';
import Code from "@material-ui/icons/Code";
import FormatPaint from "@material-ui/icons/FormatPaint";
import VpnKey from "@material-ui/icons/VpnKey";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import AccessTime from "@material-ui/icons/AccessTime";
import Smartphone from "@material-ui/icons/Smartphone";
import AccountBox from "@material-ui/icons/AccountBox";
import Icon from "@material-ui/core/Icon";
//import VerifiedUser from "@material-ui/icons/VerifiedUser";
//import Fingerprint from "@material-ui/icons/Fingerprint";
//import GroupWork from "@material-ui/icons/GroupWork";
import School from "@material-ui/icons/SchoolRounded";

import MobileFriendly from "@material-ui/icons/MobileFriendly";
import CardMembership from "@material-ui/icons/CardMembership";

import { Link } from "gatsby";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import InfoAreaBig from "components/InfoAreaBig/InfoAreaBig.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

import iphone from "assets/img/screen4.png";
import iphone2 from "assets/img/sections/iphone2.png";
import bg9 from "assets/img/bg9.jpg";
import styled, { keyframes } from 'styled-components';
import { bounce } from 'react-animations';
import { motion } from "framer-motion";
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import ScrollAnimation from "react-animate-on-scroll";
import { Grid } from "@material-ui/core";
import Features from "assets/img/main.svg";
import CardHeader from "components/Card/CardHeader.js";

import Button from "components/CustomButtons/Button.js";
const bounceAnimation = keyframes`${bounce}`;
 
const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;

const useStyles = makeStyles(featuresStyle);
const useStyle = makeStyles((theme) => ({
  root: {
    height: 180,
  },
  wrapper: {
    width: 100 + theme.spacing(2),
  },
  paper: {
    zIndex: 1,
    position: 'relative',
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
}));

export default function Sectionofferings({ ...rest }) {
  const classes = useStyles();
  const classe = useStyle();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        {/* Feature 1 START */}
        <div
        className={
          classes.sectionLite
        }
        
      >
        
        </div>
        <GridContainer>
            <GridItem md={2}>

            </GridItem>
            <GridItem md={8}>
            <div  style={{backgroundColor:'#2196F3',textAlign:'center',borderRadius: "12px"}}>
        
       
        <p className={classes.title} style={{ fontSize: '28px',color:"#FFFFFF",paddingTop:20,marginBottom:30}}>Give your Parents School Bus App today</p>
     <p className={classes.title} style={{ fontSize: '16px',color:"#FFFFFF",marginBottom:10}}>Parent one stop solution for all school bus related information</p>
        <Button round color="rose" style={{ fontSize: '16px'}}  href="/schoolsintrested">
                   Get Started
                  </Button>
        </div>
            </GridItem>
          </GridContainer>
        {/* Feature 1 END */}
        <div class="box a" data-aos="slide-right" 
    data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="1500"
   >
        <div className={classes.features3}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
           
              <motion.div whileHover={{
                scale: 1.2,
                transition: { duration: 1 },
              }}
                whileTap={{ scale: 0.9 }}
                className={classes.phoneContainer}>
                <img src={iphone} alt="..."/> 
                
               {/*  <div className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" ,
        paddingTop: 25,
        height: 0,
        textAlign:'center'
      }}>
                           <iframe
        style={{
          position: "absolute",
          top: 0,
          textAlign:'center',
          left: 0,
          width: "45%",
          height: "150%"
        }}
       
        src={`https://www.youtube.com/embed/v=ULNoeErlP54?rel=0&autoplay=1&loop=1`}
        allow='autoplay'
        allowFullScreen='allowFullScreen'
        frameBorder="0"
        setPlaybackQuality="hd1080"
        
      
      />
     
     </div> */}
              </motion.div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} style={{paddingTop:"50px"}}>
              <br/>
              <h2 className={classes.title}>BUSLOC Offerings</h2>
              <InfoArea
                className={classes.infoArea}
                icon={Group}
                title="For Parents"
                description="Parents App – BUSLOC provide you with a mobile app for receiving messages, setting up location based alerts, and for live tracking on a map."
                iconColor="rose"
              />
              <InfoArea
                className={classes.infoArea}
                icon={DirectionsBus}
                title="For Transportation Staff"
                description={<ul style={{paddingLeft:5}}>
                  <li>
                  GPS integration – we work with your GPS service provider for integration with BUSLOC.
                  </li>
                  <li>
                  Data management tool – we provide you a Dashboard for all your data management needs including schools list, routes list, route stops, and assigning Parents/Students. The Dashboard also provides you the means to send messages and switch/cancel routes. 
                  </li>
                </ul>}
               
                iconColor="rose"
              />
            {/*   <InfoArea
                className={classes.infoArea}
                icon={AccountBalance}
                title="School Administrators"
                description="Bus Tracking App – we provide you with a custom app for real-time tracking of the buses on the school’s routes and the bus status."
                iconColor="rose"
              /> */}
           
            </GridItem>
          </GridContainer>
        
         
         
        </div>
     
        </div>

    
       
       </div>
       
    </div>
  );
}
