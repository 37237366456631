import React from "react"


//import 'typeface-roboto';

//import 'typeface-roboto-slab'; 

import "assets/scss/material-kit-pro-react.scss?v=1.8.0";


import LandingPage from "../views/LandingPage/LandingPage.js"

import Layout from "../views/Layout/Layout.js"


const IndexPage = () => (
  <Layout>
    <LandingPage/>
  </Layout>
  
)

export default IndexPage
