import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons
import CardMembership from "@material-ui/icons/CardMembership";
import CardGiftCard from "@material-ui/icons/CardGiftcard";
import AttachMoney from "@material-ui/icons/AttachMoney";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";

import featuresStyle from "assets/jss/material-kit-pro-react/views/pricingSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures() {
  const classes = useStyles();
  return (
    <div className={classes.featuresSection}>
      <div className={classes.textCenter}>
        <h3 className={classes.title} style={{fontSize:'30px'}}>BUSLOC Pricing – Frequently Asked Questions</h3>
      </div>
     
          <InfoArea
            title="Is BUSLOC free?"
            description="Yes. Both SMS (text messaging),Mobile Notifications and live tracking are provided free of charge to all Transportation Staff, Drivers, Parents, and Students."
            icon={CardMembership}
            iconColor="info"
          />
      
      
          <InfoArea
            title="Is it free forever?"
            description="Yes. The SMS (text messaging),Mobile Notifications and location services are free forever. In future we may charge for additional services. "
            icon={CardGiftCard}
            iconColor="success"
          />
     
     
          <InfoArea
            title="Are there any restrictions?"
            description="No.  BUSLOC supports unlimited routes and unlimited users."
            icon={QuestionAnswer}
            iconColor="rose"
          />
     
         
    </div>
  );
}
